<template>
<b-form @submit.prevent>    
  <b-row>
     <b-col cols="6">
      <b-card-code title="Marchandise">
        <b-row>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="Catégorie"
              label-for="hi-first-name"
              label-cols-md="4"
              class="p-0 m-0 text-bold"
            >
              <b-input-group class="input-group-merge ">
                MARCHANDISES CONTENEURISEES
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="Type"
              label-for="hi-email"
              label-cols-md="4"
              class="p-0 m-0 text-bold"
            >
              <b-input-group class="input-group-merge">
                CONTENEURS 10'
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="Poids"
              label-for="hi-email"
              label-cols-md="4"
              class="p-0 m-0 text-bold"
            >
              <b-input-group class="input-group-merge">
                1,380.0 (KG)
              </b-input-group>
            </b-form-group>
          </b-col>
         
         
        </b-row>

       
      </b-card-code>

    </b-col>
    <b-col cols="6">
      <b-card-code title="Affreteur">
        <b-row>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Nom"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                TONI Momo
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0"
              label="Email"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                labayifa@gmail.com
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="Téléphone"
              class="p-0 m-0"
              label-for="hi-number"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                +22964644775
              </b-input-group>
            </b-form-group>
          </b-col>
         
         
        </b-row>
      </b-card-code>

    </b-col>
    
    <b-col cols="12">
      <b-card-code title="Moyen de transport">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Catégorie"
              class="pt-0 mt-0"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge ">
                CAMION CITERNE
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Type"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                CITERNE POUR PULVERULENTS
              </b-input-group>
            </b-form-group>
          </b-col>
         
         
        </b-row>

       
      </b-card-code>

    </b-col>

  </b-row>
  <b-row>
    <b-col cols="6">
      <b-card-code title="Départ">
        <b-row>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Pays"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                KPASSE ( OUIDAH )
              </b-input-group>
            </b-form-group>
          </b-col>
        <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Région"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                KPASSE ( OUIDAH )
              </b-input-group>
            </b-form-group>
          </b-col>
        <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Ville"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                KPASSE ( OUIDAH )
              </b-input-group>
            </b-form-group>
          </b-col>
        
         
         
        </b-row>
      </b-card-code>

    </b-col>
   <b-col cols="6">
      <b-card-code title="Destination">
        <b-row>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Pays"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                KPASSE ( OUIDAH )
              </b-input-group>
            </b-form-group>
          </b-col>
          
         
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Region"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                KPASSE ( OUIDAH )
              </b-input-group>
            </b-form-group>
          </b-col>
          
           <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Ville"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                KPASSE ( OUIDAH )
              </b-input-group>
            </b-form-group>
          </b-col>
          
        </b-row>
      </b-card-code>

    </b-col>

  </b-row>


</b-form>

</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'

import {
  BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BRow, BCol,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import { codeHorizontalIcon } from './code'
// import { codeBasic } from './dcode'


export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    vSelect,
    BButton,
    flatPickr,

    BDropdown,
    BDropdownItem,
    BDropdownDivider,

  },
  directives: {
    Ripple,

  },
  data() {
    return {
      codeHorizontalIcon,
      
      date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,
      codeBasic,

      selected: { title: 'Chox du camion' },
      option: [{ title: 'Chox du camion' }, { title: 'RB 4526 (Chassis: 12345678)' }, { title: 'RB 4567 (Chassis: 12345678)' }, { title: 'RB 4896 (Chassis: 12345678)' }],

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
